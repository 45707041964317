.BlogDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BlogDetail-top-container h6 {
  padding-top: 64px;
  padding-bottom: 16px;
}

.BlogDetail-top-container p {
  padding-bottom: 32px;
}

.BlogDetail-top-container,
.BlogDetail-middle-container {
  padding-inline: 15%;
}

.BlogDetail img {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  /* border-radius: 16px; */
  /* margin: 32px; */
}

@media screen and (max-width: 1080px) {
  .BlogDetail-top-container,
  .BlogDetail-middle-container {
    padding-inline: 32px;
  }
}
