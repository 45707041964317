/* CTACard.css */

.CTACard {
  /* background-color: rgba(0, 0, 0, 0); */
  background-color: #ececec;
  /* #FFBCA1; */
  border-radius: 16px;
  padding: 32px;
  text-align: center;
}

.CTACard h2 {
  background-color: rgba(0, 0, 0, 0);
  color: black;
  margin-top: 0px;
  margin-bottom: 0px;
}

.CTACard p {
  background-color: rgba(0, 0, 0, 0);
  color: black;
  margin-top: 8px;
  margin-bottom: 32px;
}

/* .CTAButton {
    color: white;
    background-color: #FF3D00;
    height: 5vh;
    width: 10vw;
    
} */

.CTAButton {
  background-color: #ff3d00;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 48px;
  aspect-ratio: 4/1;
  font-size: 1rem;
  font-weight: bold;
}

.CTAButton:hover {
  background-color: #cd3203;
  font-size: 1.1rem;
  /* slightly darker color on hover */
}
