.portfolio-container {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 
.portfolio-container iframe {
  width: 100%;
  height: 100%;
} */

.portfolio-iframe-container {
  /* position: relative; */
  width: 100%;
  height: 0;
  padding-top: 56.2225%;
  padding-bottom: 32px;
  /* box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16); */
  margin-top: 0;
  /* 1.6em; */
  margin-bottom: 0;
  /* 0.9em; */
  overflow: hidden;
  /* border-radius: 8px; */
  will-change: transform;
  background-color: black;
}

.portfolio-iframe-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1366px) {
  .portfolio-container img {
    width: 100%;
  }
}
