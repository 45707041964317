.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

body {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-image: linear-gradient(60deg, #29323c 0%, #485563 100%); */
  background-color: #222222;
}

* {
  /* background-color: #222222; */
  color: white;
  /* background-color: #F0EFE9;
  color: #2E2A22; */
}

/*----------------------- Header Section -> ----------------------- */

.App-header {
  /* background-color: #ECECEC; */
  /* padding: 8px;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center; 
  font-size: calc(10px + 2vmin);
  margin: 0; */
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* width: 100%; */
  position: relative;
  text-align: center;
  background-color: #ececec;
  /* #F4C670; */
  overflow: hidden;
  height: 86px;
}

.App-header-title-icon-container {
  background-color: #00000000;
  /* display: flex; */
  /* flex-direction: row; */
  position: absolute;
  left: 0px;
  margin: 16px;
  margin-left: 32px;
}

.App-icon-horizontal {
  position: absolute;
  background-color: #00000000;
  height: 54px;
  aspect-ratio: 131/54;
  align-self: center;
}

.App-header-title {
  background-color: #00000000;
  color: #000000;
  font-size: calc(1rem + 2vw);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px;
}

.App-navigation {
}

@media screen and (max-width: 1080px) {
  .App-header {
    height: 134px;
  }
  .App-header-title {
    width: 100%;
    top: 70%;
  }
  .App-header-title-icon-container {
    width: 100%;
    /* margin: 0px; */
    margin-left: 0px;
  }

  .App-icon-horizontal {
    left: 50%;
    transform: translate(-50%, 0);
  }
}
/*----------------------- Header Section <- ----------------------- */

/*----------------------- Navigation Section -> ----------------------- */

.topnav {
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #00000000;
  overflow: hidden;
  /* position: absolute; */
  /* right: 16px; */
  flex-direction: row;
  display: flex;
  flex: 150px auto;
  margin: 16px;
  /* float: right; */
}

/* Style the links inside the navigation bar */
.topnav a {
  /* float: right; */
  background-color: #00000000;
  color: #646464;
  text-align: right;
  padding: 18px 16px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #00000000;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #00000000;
  color: black;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.topnav .icon {
  display: none;
}

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 1200px) {
  .topnav a {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
    padding: 9px;
  }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 1200px) {
  .topnav.responsive {
    background-color: red;
    /* position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px; */
  }

  .topnav.responsive a.icon {
    /* position: absolute;
    right: 0;
    top: 0;
    padding: 0px; */
  }

  .topnav.responsive a {
    /* float: none;
    display: block;
    text-align: left; */
  }
}

/*----------------------- Navigation Section <- ----------------------- */

.CTACard-container {
  padding-left: 32px;
  padding-right: 32px;
  background-color: #ffea7f00;
  /* #222222; */
  /* #4A4A4A; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotateY(0deg);
    /* rotate(0deg); */
  }
  to {
    transform: rotateY(360deg);
  }
}
