.active {
  color: black;
  text-decoration: underline;
  text-underline-offset: 5px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ececec;
  position: sticky;
  top: 0;
  z-index: 1;
}

nav .title {
  font-size: 1.5rem;
  margin: 1rem;
  padding-inline: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: #000000;
}

nav .horizontalLogo {
  object-fit: fill;
  height: 44px;
  aspect-ratio: 131/54;
}

nav ul {
  display: flex;
  padding-inline: 1rem;
  margin-inline: 1rem;
}

nav ul.open {
  display: flex;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: #646464;
  padding: 0.5rem;
  margin: 0.5rem;
}

nav ul li a:not(.active):hover {
  color: black;
}

nav .navMenu {
  display: none;
  position: absolute;
  top: 1.2rem;
  /* transform: translateY(-50%); */
  right: 16px;
  flex-direction: column;
  justify-content: space-evenly;
  width: 24px;
  height: 24px;
}

nav .navMenu span {
  height: 2px;
  width: 20px;
  aspect-ratio: 1/1;
  background-color: #000000;
  border-radius: 0.1rem;
}

@media (max-width: 1080px) {
  nav .navMenu {
    display: flex;
  }

  nav {
    flex-direction: column;
    align-items: start;
    align-items: center;
  }

  nav .image {
    margin-bottom: 1rem;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
    padding: 0;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
  }
}
