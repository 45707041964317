/* OurWork.css */

.OurWork-section-container {
  background-color: #00000000;
}

.OurWork-section-container h2 {
  padding-left: 32px;
  /* color: white; */
}

.OurWork-section {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
}

.OurWork-section a:link {
  text-decoration: none;
}

.OurWork-section a:hover {
  background-color: rgb(46, 46, 46);
  border-radius: 16px;
}

/*--------------------- Our work Card Section ---------------------*/
.OurWorkCard-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
}

.OurWorkCard-section:nth-child(even) {
  flex-direction: row-reverse;
}

.OurWorkCard-image-container {
  width: 30%;
  aspect-ratio: 1/1;
}

.OurWorkCard-title-description {
  flex: 1;
  padding: 32px;
  padding-left: 5%;
  text-align: center;
  /* color: white; */
}

.OurWorkCard-title-description h2 {
  font-size: calc(1rem + 1vw);
}

.OurWorkCard-title-description:nth-child(even) {
  padding-right: 5%;
}

@media screen and (max-width: 1080px) {
  .OurWork-section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 16px;
    /* padding: 32px; */
  }

  .OurWorkCard-section {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
    /* margin-top: 32px; */
    margin-bottom: 0px;
    text-align: center;
  }

  .OurWorkCard-image-container {
    width: 50%;
  }

  .OurWorkCard-title-description {
    padding: 0px;
    padding-left: 0px;
    text-align: center;
    justify-content: space-between;
    /* color: white; */
  }
  .OurWorkCard-title-description:nth-child(even) {
    padding-right: 0px;
  }

  .OurWorkCard-section:nth-child(even) {
    flex-direction: column;
  }

  .OurWorkCard-title-description h2 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .OurWorkCard-title-description p {
    padding-left: 0px;
    padding-right: 0px;
    bottom: 0px;
    text-align: justify;
  }
}
