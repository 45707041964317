/* WelcomeSection.css */

.WelcomeSection {
  position: relative;
  text-align: center;
  background-color: #f4c670;
  /* #897C7C; */
  overflow: hidden;
  height: 244px;
}

.WelcomeSection-highlight-circle {
  position: absolute;
  top: -156px;
  left: 50%;
  transform: translate(-50%, 0);
  /* left: ; */
  width: 400px;
  height: 400px;
  border-radius: 200px;
  /* background-color: red; */
  background-image: linear-gradient(0deg, #ececec50 0%, #48556300 50%);
}

.WelcomeSection-title-description {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 70px;
}

.WelcomeSection-title-description h1 {
  color: black;
  font-size: 64px;
  margin: 0px;
}

.WelcomeSection-title-description p {
  color: black;
}
