.BlogCardFeatured {
  background-color: #ffbca1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  /* border-radius: 16px; */
}

.BlogCardFeatured-title-description {
  flex-direction: column;
  width: 50%;
}

.BlogCardFeatured-title-description h1 {
  font-size: 2rem;
  color: black;
}

.BlogCardFeatured-title-description p {
  font-size: 1.2rem;
  color: #464646;
}

.BlogCardFeatured img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  aspect-ratio: 503/411;
  object-fit: contain;
}

.BlogCardFeatured-image {
  width: 40%;
  border-radius: 50%;
  background-color: #ffa77f;
}

.BlogCardFeatured-button-container button {
  margin-top: 32px;
  background-color: #ff3d00;
  color: white;
  font-size: 1rem;
  font-weight: lighter;
  width: 190px;
  aspect-ratio: 190/55;
  border-radius: 16px;
  border: none;
  cursor: pointer;

  position: relative;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    background-color: #d73403;
  }
}

@media screen and (max-width: 1080px) {
  .BlogCardFeatured {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0px;
  }

  .BlogCardFeatured-title-description {
    padding-inline: 32px;
    width: auto;
  }

  .BlogCardFeatured img {
    width: 80%;
    padding: 32px;
    /* width: auto; */
  }

  .BlogCardFeatured-image {
    margin: 32px;
    width: auto;
  }

  .BlogCardFeatured button {
    margin-bottom: 32px;
  }
}
