.Blog a:link {
  text-decoration: none;
}

.Blog .title {
  background-color: #ffbca1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-items: center;
  align-items: center;
  /* background-image: linear-gradient(0deg, #9747ff 0%, rgb(189, 142, 232) 100%); */
}

.title h1 {
  color: black;
}

.blog-section {
  margin: 16px;
  /* padding: 16px; */
  display: grid;
  text-decoration: none;
}

.blog-section a:hover {
  background-color: rgb(46, 46, 46);
  border-radius: 16px;
}

.blog-section-item {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  padding-block: 1rem;
  border-radius: 16px;
}

.blog-section-item img {
  width: 20%;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: 8px;
  margin-inline: 16px;
}

.blog-section-item-title-description h2 {
  padding-inline: 1rem;
  font-size: 1.5rem;
}

.blog-section-item-title-description h1 {
  padding-inline: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: rgb(201, 201, 201);
}

.blog-section-item-title-description p {
  padding-inline: 1rem;
  font-size: 1rem;
  color: rgb(201, 201, 201);
}

@media screen and (max-width: 1080px) {
  .blog-section-item {
    flex-direction: column;
    margin-bottom: 2rem;
    margin-inline: 16px;
  }

  .blog-section-item img {
    width: 100%;
  }

  .blog-section-item-title-description h2 {
    /* text-align: justify; */
    padding-inline: 0;
    margin: 0;
    padding-block: 1rem;
  }

  .blog-section-item-title-description h1 {
    padding-inline: 0;
    padding-bottom: 1.5rem;
    margin: 0;
  }

  .blog-section-item-title-description p {
    text-align: justify;
    padding-inline: 0;
    margin: 0;
  }
}
