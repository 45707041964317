.Contact {
  display: flex;
  flex-direction: column;
  padding: 32px;
  /* background-image: linear-gradient(180deg, #505050 0%, #00000000 96px); */
}

.Contact-left-section {
  flex-direction: column;
  padding-bottom: 32px;
}

.Contact-left-top-section h2 {
  font-size: 2rem;
  margin-block: 1rem;
}

.Contact-left-top-section p {
  /* font-size: 1rem; */
}

.Contact-left-bottom-section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 64px;
}

.Contact-left-bottom-section-item {
  padding-right: 32px;
}

.Contact-left-bottom-section-item h2 {
  font-size: 1rem;
}

.Contact-left-bottom-section-item p {
  font-size: 0.8rem;
}

.Contact-right-section {
  width: 80%;
  /* aspect-ratio: 1/1; */
  background-color: rgb(46, 46, 46);
  border-radius: 16px;
  padding-block: 32px;
  padding-inline: 64px;
  margin-left: 32px;
}

.Contact-right-section label {
  color: white;
}

/* .Contact-right-section input {
  background-color: rgb(76, 76, 76);
  color: white;
  text-decoration: none;
} */

@media screen and (max-width: 1080px) {
  .Contact {
    flex-direction: column;
  }

  .Contact-right-section {
    margin-inline: 0px;
    width: auto;
  }

  .Contact-left-bottom-section-item {
    padding-right: 0px;
  }

  .Contact-left-bottom-section {
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 32px;
  }

  .Contact-right-section {
    padding-inline: 32px;
  }
}
