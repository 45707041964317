/* Footer.css */

.footer {
  margin-top: 32px;
  background-color: #2b2b2b;
  /* #33333300; */
  color: #fff;
  padding: 16px 32px;
  padding-bottom: 32px;
}

.footer-section-section1 {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer-section-section2 {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.footer-section-about,
.footer-section-social,
.footer-section-contact,
.footer-section-TFS {
  flex: 1;
  /* margin: 0 20px; */
}

.footer-section-about h2,
.footer-section-TFS h2,
.footer-section-social h2,
.footer-section-contact h2 {
  color: #ffaa00;
  /* #F9C678; Yellow*/
  /* #FFD600; */
  /* #ff3c00d2; */
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.footer-section-contact-item,
.footer-section-social-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer-section-TFS svg {
  width: 232.8;
  height: 96;
}

.footer-section-contact svg,
.footer-section-social svg {
  margin-inline: 0.5rem;
  fill: #ffaa00;
  width: 16px;
}

.footer-section-contact p,
.footer-section-social p {
  font-size: 1rem;
  margin: 0.5rem;
  text-decoration: none;
}

.footer-section-contact a:link,
.footer-section-social a:link {
  font-size: 1rem;
  margin: 0.5rem;
  text-underline-offset: 6px;
}

@media screen and (max-width: 1024px) {
  .footer-section-section2 {
    flex-direction: column;
  }
}

/* .footer-section-social ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.footer-section-social ul li {
  list-style: none;
  text-decoration: underline;
  text-underline-offset: 0.5rem;
}

.footer-section-social ul li a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 0.5rem;
  flex-direction: row;
} */

/* .footer-section-social-item svg {
  width: 1.5rem;
  aspect-ratio: 1/1;
  color: white;
} */

/* .footer-section-social-item {
  flex-direction: column;
} */
