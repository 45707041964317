/* TestimonialSection.css */

.Testimonial-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  /* repeat(auto-fill, minmax(320px, 1fr)); */
  gap: 32px;
  padding: 32px;
  text-align: center;
  /* background-color: #00000000; */
}

.TestimonialCard-image {
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 70%;
  border-radius: 50%;
}

.TestimonialCard-title-description {
  padding-top: 16px;
}

.TestimonialCard-title-description h4 {
  /* margin: 16px; */
  font-size: 14px;
}

.TestimonialCard-title-description p {
  margin: 0;
  font-size: 14px;
}
