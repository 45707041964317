/* ImageHolder.css */

.ImageHolder-container {
  width: 100%;
  aspect-ratio: 1/1;
}

.ImageHolder-bgView {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #d4d3d1;
}

.ImageHolder-image {
  top: -8px;
  left: 8px;
  position: relative;
  flex: 1;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 8px;
}
