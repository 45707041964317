/* Form.css */

.custom-form {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  /* margin: 0 auto; */
}

.rounded-input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  color: black;
  font-size: 1rem;
}

.rounded-input-textarea {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  height: auto;
  color: black;
  font-size: 1rem;
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #ff3d00;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #d83200;
}
