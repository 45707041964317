/* SectionHeader.css */

.SectionHeader-container {
    display: flex;
    flex-direction: column;
    background-color: #00000000;
}

.SectionHeader {
    flex: 1;
    text-align: center;
    /* justify-content: center; */
    /* align-items: center; */
    /* flex-direction: column; */
    background-color: #00000000;
}

.SectionHeader-container h2 {
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-size: calc(1.0rem + 1vw);
    /* color: white; */
}

.SectionHeader-container p {
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    /* font-weight: normal; */
    /* color: #2E2A22; */
}