/* WelcomeHeader.css */

.WelcomeHeader {
  display: flex;
  flex-direction: row;
}

.WelcomeHeader-info-frame {
  background-color: #282c34;
  /* padding: 96px; */
  position: relative;
  width: 63.955%;
}

.WelcomeHeader-info-content {
  position: absolute;
  background-color: #00000000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.WelcomeHeader-info-frame h1 {
  margin: 16px;
  color: #ffaa00;
  font-size: calc(1rem + 2.5vw);
}

.WelcomeHeader-info-frame h2 {
  margin: 16px;
  color: #ffaa00;
  font-size: calc(1rem + 0.7vw);
}

.WelcomeHeader-info-frame p {
  margin: 0px;
  font-size: 28px;
  font-weight: 600;
}

.WelcomeHeader-product-frame {
  background-color: #ffaa00;
  position: relative;
  width: 36.11%;
  aspect-ratio: 1/1;
}

.WelcomeHeader-product-focusBG {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
  aspect-ratio: 1/1;
}

.WelcomeHeader-product {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 62.6271%;
  aspect-ratio: 1/1;
  /* animation-name: Opacity-Stay, Opacity-Increase, Opacity-Decrease;
    animation-duration: 3s;
    animation-iteration-count: infinite; */
}

.WelcomeHeader-product2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 62.6271%;
  aspect-ratio: 1/1;
  /* animation-name: Opacity-Stay, Opacity-Increase, Opacity-Decrease, Opacity-Stay;
    animation-duration: 4s;
    animation-iteration-count: infinite; */
}

.WelcomeHeader-product3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 62.6271%;
  aspect-ratio: 1/1;
  /* animation-name: Opacity-Stay, Opacity-Stay, Opacity-Increase, Opacity-Decrease;
    animation-duration: 4s;
    animation-iteration-count: infinite; */
}

@media screen and (max-width: 568px) {
  .WelcomeHeader {
    flex-direction: column-reverse;
  }

  .WelcomeHeader-info-frame {
    width: 100%;
    aspect-ratio: 4/3;
  }

  .WelcomeHeader-product-frame {
    width: 100%;
    aspect-ratio: 430/220;
  }

  .WelcomeHeader-product-focusBG {
    width: 100%;
    height: 100%;
  }

  .WelcomeHeader-product {
    width: 41.86%;
  }

  .WelcomeHeader-info-frame p {
    margin: 0px;
    font-size: 22px;
    font-weight: 400;
  }
}

/* Animations */
@keyframes Opacity-Decrease {
  from {
    opacity: 100%;
    /* transform: translateX(0); */
    transform: translate(-50%, -50%);
  }

  to {
    opacity: 0%;
    /* transform: translateX(-200); */
    transform: translate(-50%, -50%);
  }
}

@keyframes Opacity-Increase {
  from {
    opacity: 0%;
    /* transform: translateX(200); */
    transform: translate(250%, -50%);
  }

  to {
    opacity: 100%;
    /* transform: translateX(0); */
    transform: translate(-50%, -50%);
  }
}

@keyframes Opacity-Stay {
  from {
    opacity: 0%;
    /* transform: translateX(200); */
    /* transform: translate(-50%, -50%); */
  }

  to {
    opacity: 0%;
    /* transform: translateX(200); */
    /* transform: translate(250%, -50%); */
  }
}
