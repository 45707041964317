/* ServicesSection.css */

.Service-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 32px;
  padding: 32px;
  background-color: #00000000;
}

.ServiceCard-section {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-align: center;
}

.ServiceCardImage {
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
  border-radius: 16px;
}

.ServiceCardVideo {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 16px;
}

.ServiceCard-titleDescription {
  padding: 0px;
}

.ServiceCard-titleDescription h2 {
  margin: 16px;
  font-size: 18px;
  /* color: white; */
}

.ServiceCard-titleDescription p {
  margin: 0;
  font-size: 14px;
  /* color: #2E2A2270; */
  /* color: #666; */
}

@media screen and (max-width: 1080px) {
}
