.About {
  padding: 32px;
  /* background-color: red; */
  /* background-image: linear-gradient(180deg, #505050 0%, #00000000 96px); */

  /* radial-gradient(#8b8b8b71 0%, #00000000 30%); */
}

.about-image {
  width: 100%;
}

.text-gradient {
  /* linear-gradient(90deg, #ffffff74 20%, #0000006b 100%); */
}

.About p {
  /* padding-right: 50%; */
  /* position: absolute;
  bottom: 0px; */
  font-size: 1rem;
  color: white;
}
