/* IntroSection.css */

.Intro-section-container {
  /* background-color:#0B0B0D; */
  display: flex;
  margin: 32px;
}

.Intro-section {
  display: flex;
  flex-direction: row;
}

.Intro-section-image {
  /* height: 100vh; */
  /* margin-left: 32px; */
  width: 50vw;
  aspect-ratio: 1.1/1;
  object-fit: cover;
  border-radius: 1%;
}

.Intro-section-content {
  /* display: flex; */
  /* align-content: center; */
  margin-left: 16px;
  margin-bottom: 16px;
  /* color: white; */
  font-size: 17px;
  /* calc(6px + 2vmin); */
  text-align: left;
  line-height: 40px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1080px) {
  .Intro-section {
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

  .Intro-section-image {
    width: 100%;
    aspect-ratio: 1.5/1;
  }

  .Intro-section-content {
    margin-left: 0px;
    text-align: justify;
  }
}
